<template>
    <v-container>
        <v-card>
            <v-card-title>
                Criar Registro de Pessoa

                <v-divider
                    class="mx-4"
                    inset
                    vertical
                ></v-divider>
                <v-spacer></v-spacer>

                <v-btn
                    color="indigo darken-1"
                    dark
                    fab
                    small
                    :to="{name: 'people.read'}"
                >
                    <v-icon>
                        mdi-arrow-left
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-alert
                    v-model="alert.show"
                    dismissible
                    :color="alert.type"
                    border="left"
                    elevation="2"
                    colored-border
                    transition="scale-transition"
                >
                    {{alert.text}}
                </v-alert>

                <v-alert
                    v-model="alertSuccess.show"
                    dismissible
                    :color="alert.type"
                    border="left"
                    elevation="2"
                    colored-border
                    transition="scale-transition"
                >
                    {{alertSuccess.text}}
                </v-alert>

                <v-form ref="form" >
                    <v-row>
                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                label="Nome"
                                v-model="form.nome"
                                counter
                                maxlength="64"
                                :rules="[
                                    form_nome_rules.required, form_nome_rules.counter,
                                        form_nome_rules.min
                                ]"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-autocomplete
                                label="Tipo de Pessoa"
                                v-model="form.razao_social"
                                :items="sectionsRazaoSocial"
                                :rules="[form_razao_social_rules.required]"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-dialog
                                ref="dialog"
                                v-model="modal"
                                :return-value.sync="form.nascimento"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        label="Data de Nascimento"
                                        v-model="form.nascimento"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="form.nascimento"
                                    scrollable
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="modal = false"
                                    >
                                        Cancela
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dialog.save(form.nascimento)"
                                    >
                                        Ok
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                label="CPF/CNPJ"
                                v-model="form.cnpj"
                                maxlength="18"
                                :rules="[form_cnpj_rules.required, form_cnpj_rules.min]"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                label="Inscriçõa Estadual"
                                v-model="form.inscricao"
                                maxlength="16"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                label="Telefone"
                                v-model="form.telefone"
                                maxlength="14"
                                v-mask="'(##) ####-####'"
                                :rules="[form_telefone_rules.counter]"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                label="Celular"
                                v-model="form.celular"
                                maxlength="13"
                                v-mask="'## #####-####'"
                                :rules="[form_celular_rules.counter]"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                v-model="form.email"
                                label="Email"
                                type="email"
                                maxlength="64"
                                :rules="[form_email_rules.email, form_email_rules.counter]"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                label="Site"
                                v-model="form.site"
                                maxlength="40"
                                :rules="[form_site_rules.counter]"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                label="Estado Civil"
                                v-model="form.estado_civil"
                                maxlength="15"
                                :rules="[form_estado_civil_rules.counter]"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                label="Pessoa Juridica"
                                v-model="form.pessoa_juridica"
                                maxlength="1"
                                :rules="[form_pessoa_juridica_rules.counter]"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-autocomplete
                                label="Sexo"
                                v-model="form.sexo"
                                :items="sectionsSexo"
                                :rules="[form_sexo_rules.required]"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-autocomplete
                                label="Bloqueado?"
                                v-model="form.bloqueado"
                                :items="sectionsBlock"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-autocomplete
                                label="Desativado?"
                                v-model="form.desativado"
                                :items="sectionsDisabled"
                            ></v-autocomplete>
                        </v-col>

                        <!-- <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                label="Integrado"
                                v-model="form.integrado"
                                maxlength="4"
                                :rules="[form_integrado_rules.counter]"
                            ></v-text-field>
                        </v-col> -->
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-btn
                    color="indigo darken-1"
                    dark
                   @click.prevent="save()"
                >
                    <v-icon>
                        mdi-save
                    </v-icon>

                    Salvar
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    color="pink darken-1"
                    dark
                    @click.prevent="returnRead()"
                >
                    <v-icon>
                        mdi-block
                    </v-icon>

                    Cancelar
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular
                size="100"
                color="indigo darken-1"
            >
            </v-progress-circular>
        </v-overlay>
    </v-container>
</template>
<script>
import router from '../../router'
export default {
    name: 'vwpeopleCreate',
    data(){
        return {
            overlay: false,
            modal: false,
            form: {
                nome: '',
                razao_social: null,
                nascimento: new Date().toISOString().substr(0, 10),
                cnpj: '',
                inscricao: '',
                telefone: '',
                celular: '',
                email: '',
                site: '',
                estado_civil: '',
                pessoa_juridica: '',
                sexo: '',
                bloqueado: '',
                desativado: '',
                integrado: ''
            },
            alert: {
                show: false,
                type: 'indigo',
                text: ''
            },
            alertSuccess: {
                show: false,
                type: 'indigo',
                text: ''
            },

            sectionsRazaoSocial: ['Fisica', 'Juridica'],

            sectionsSexo: ['M', 'F'],

            sectionsBlock: ['S', 'N'],

            sectionsDisabled: ['S', 'N'],

            form_nome_rules: {
                required: value => !!value || 'Nome é obrigatório.',
                counter: value => value.length <= 64 || 'Dados inválido!',
                min: v => v.length >= 2 || 'Mínimo de 2 caracteres'
            },

            form_razao_social_rules: {
                required: value => !!value || 'Razão social é obrigatória.'
            },

            form_cnpj_rules: {
                required: value => !!value || 'CPF/CNPJ é obrigatório.',
                min: v => v.length >= 11 || 'Dados incorreto'
            },

            form_telefone_rules: {
                counter: value => value.length <= 14 || 'Dados inválido!'
            },

            form_celular_rules: {
                counter: value => value.length <= 13 || 'Dados inválido!'
            },

            form_email_rules: {
				counter: value => value.length <= 64 || 'Email inválido',
				email: value => {
                    if(this.form.email == '') {
                        return true
                    } else {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'E-mail inválido.'
                    }
				}
			},

            form_site_rules: {
                counter: value => value.length <= 40 || 'Dados inválido!'
            },

            form_estado_civil_rules: {
                counter: value => value.length <= 15 || 'Dados inválido!'
            },

            form_pessoa_juridica_rules: {
                counter: value => value.length <= 1 || 'Dados inválido!'
            },

            form_sexo_rules: {
                required: value => !!value || 'Sexo é obrigatório.'
            },

            form_integrado_rules: {
                counter: value => value.length <= 4 || 'Dados inválido!'
            },
        }
    },

    computed:{
        id: function(){
            return this.$route.params.id
        }
    },

    methods: {
        clearForm: function() {
            for (let prop in this.form) {
                this.form[prop] = ''
            }
        },

        returnRead: function() {
            router.push({ name: 'people.read' })
        },
    
        save: function(){
            if(this.$refs.form.validate()){
                this.overlay = true
                if(this.id !== undefined){
                    this.update()
                }else{
                    this.create()
                }
            }
        },

        update: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + 'peoples/update/' + this.id, {
                method: 'PUT',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.clearForm()
                    this.returnRead()
                })
                .catch(error => {
                    console.error(error)
                    this.alert = {
                        text: 'Erro interno ao atualizar o regitro!',
                        show: true,
                        type: 'red darken-1'
                    }
                })
                .finally(() => {
                    this.overlay = false
                })
        },

        create: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + 'peoples/create', {
                method: 'POST',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.alertSuccess = {
                        text: 'Registro cadastrado com sucesso.',
                        show: true,
                        type: 'red darken-1'
                    }
                    this.clearForm()
                })
                .catch(error => {
                    console.error(error)
                    this.alert = {
                        text: 'Erro interno ao gravar o registro!',
                        show: true,
                        type: 'red darken-1'
                    }
                })
                .finally(() => {
                    this.overlay = false
                })
        },

        getData: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + 'peoples/read/' + this.id, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    // console.log(resJSON)
                    this.form = resJSON.response[0].listhelper[0]
                    console.log(this.form)
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.overlay = false
                })
        },

        getSections: function(){
            fetch(process.env.VUE_APP_API + '/generics/description/setores_impressao', {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.sections = resJSON.response
                })
                .catch(error => {
                    console.error(error)
                })
        }
    },

    mounted(){
        if (this.id != undefined) {
            this.getData()
        }

        // this.getSections()
    }
}
</script>